import { defineStore } from "pinia"
import { format, addDays } from "date-fns"
import {
  fetchApiJson,
  makeDataFetchObject,
  prepareDataFetch,
  handleDataFetchPromise,
} from "@/services/repowerAPIService.js"

export default function (assetId) {
  const key = `singleAssetStore-${assetId}`
  return defineStore(key, {
    state: () => ({
      properties: {},
      status: null,
      measurements: makeDataFetchObject(),
      calculatedRevenue: makeDataFetchObject(),
      recordStreamCollections: makeDataFetchObject(),
      tableData: makeDataFetchObject(),
      fcrAllocations: makeDataFetchObject(),
      setpoints: makeDataFetchObject(),
      exportData: makeDataFetchObject(),
      isAsset: true,
    }),
    getters: {
      id: (state) => state.properties?.id,
      name: (state) => state.properties?.name,
      type: (state) => state.properties?.type,
      portfolioId: (state) => state.properties?.portfolio_id,
      ean: (state) => state.properties?.allocation_point_ean,
      icon: (state) =>
        state.properties?.type == "Battery"
          ? "battery"
          : state.properties?.type == "Consumer"
            ? "database"
            : "sun",
    },
    actions: {
      setAsset(asset) {
        this.properties = asset
        this.status = asset.status
      },
      loadStatus(onSuccess, onFail) {
        fetchApiJson(`/v2/assets/${this.id}/status/`)
          .then((data) => {
            this.status = data
            if (onSuccess) onSuccess(data)
          })
          .catch((error) => {
            this.status = null
            if (onFail) onFail(error)
          })
      },
      loadMeasurements(freq, dateStart, dateEnd, getExport, onSuccess, onFail) {
        let cache = this.returnCache(getExport)
        const result = prepareDataFetch(
          cache,
          freq,
          dateStart,
          dateEnd,
          null,
          null,
          onSuccess,
        )
        if (!result) return
        let { params, uid } = result
        const measurementTypes = this.type == "Battery" ? "power,soc" : "power"
        const promise = fetchApiJson(
          `/v2/assets/${this.id}/measurements/${getExport ? "export/" : ""}` +
            `?${params}&measurement_type=${measurementTypes}`,
        )

        handleDataFetchPromise(cache, uid, promise, params, onSuccess, onFail)
      },
      loadCalculatedRevenue(freq, dateStart, dateEnd, onSuccess, onFail) {
        // #TODO(REP-2961- Refactor the different ...fetchData functions)
        const result = prepareDataFetch(
          this.calculatedRevenue,
          freq,
          dateStart,
          dateEnd,
          null,
          null,
          onSuccess,
        )
        if (!result) return
        let { params, uid } = result
        const promise = fetchApiJson(
          `/v2/assets/${this.id}/calculated-revenue/?${params}&revenue_types=imbalance`,
        )
        handleDataFetchPromise(
          this.calculatedRevenue,
          uid,
          promise,
          params,
          onSuccess,
          onFail,
        )
      },
      loadBatteryCycles(dateStart, dateEnd, onSuccess, onFail) {
        const params = new URLSearchParams({
          date_start: format(dateStart, "yyyy-MM-dd"),
          date_end: format(addDays(dateEnd, 1), "yyyy-MM-dd"),
        }).toString()
        fetchApiJson(`/v2/assets/${this.id}/kpis/battery-cycles/?${params}`)
          .then((data) => {
            if (onSuccess) onSuccess(data)
          })
          .catch((error) => {
            if (onFail) onFail(error)
          })
      },
      loadRecordStreamCollection(
        freq,
        dateStart,
        dateEnd,
        getExport,
        onlyTotals,
        onSuccess,
        onFail,
        // used to determine which cache to use
        isResultsTable = false,
      ) {
        let cache = this.returnCache(getExport, isResultsTable)
        const result = prepareDataFetch(
          cache,
          freq,
          dateStart,
          dateEnd,
          false,
          onlyTotals,
          onSuccess,
        )
        if (!result) return
        const { params, uid } = result
        const promise = fetchApiJson(
          `/v2/assets/${this.id}/record-stream-collections/`,
        ).then((collections) => {
          if (collections.length == 0) throw new Error("No collections present")
          return fetchApiJson(
            `/v2/record-streams/${collections[0].collection_id}/` +
              `${getExport ? "export/" : ""}?${params}`,
          )
        })
        handleDataFetchPromise(cache, uid, promise, params, onSuccess, onFail)
      },
      loadFcrAllocations(dateStart, dateEnd, onSuccess, onFail) {
        const result = prepareDataFetch(
          this.fcrAllocations,
          null,
          dateStart,
          dateEnd,
          null,
          null,
          onSuccess,
        )
        if (!result) return
        const { params, uid } = result
        const promise = fetchApiJson(
          `/v2/assets/${this.id}/fcr-allocations/?${params}`,
        )
        handleDataFetchPromise(
          this.fcrAllocations,
          uid,
          promise,
          params,
          onSuccess,
          onFail,
        )
      },
      loadSetpoints(dateStart, dateEnd, onSuccess, onFail) {
        const result = prepareDataFetch(
          this.setpoints,
          null,
          dateStart,
          dateEnd,
          null,
          null,
          onSuccess,
        )
        if (!result) return
        const { params, uid } = result
        const promise = fetchApiJson(
          `/v2/assets/${this.id}/setpoints/?${params}`,
        )
        handleDataFetchPromise(
          this.setpoints,
          uid,
          promise,
          params,
          onSuccess,
          onFail,
        )
      },
      returnCache(getExport, isResultsTable) {
        if (getExport) {
          return this.exportData
        }
        return isResultsTable ? this.tableData : this.recordStreamCollections
      },
    },
  })() // <-- Note the () here to call the function
}
