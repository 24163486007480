<template>
  <BaseCard class="flex flex-col gap-2 p-4 md:gap-4 md:p-6">
    <TextSubtitle v-if="cardTitle" class="mx-2">{{ cardTitle }}</TextSubtitle>
    <LoadingWrapper
      v-if="meteringPoint"
      :error="loadingFailed"
      :loading="loading"
    >
      <!-- Stat row -->
      <div class="flex justify-between">
        <!-- Stat allocation -->
        <StatComponent
          :label="'Energie' + (totals.isFinal ? '' : ' (voorlopig)')"
          :value="totals.allocation * 1000"
          :precision="2"
          unit="Wh"
          class="flex-1"
        >
          <template #icon>
            <BaseIcon name="flashlight" filled class="fill-energy" />
          </template>
          <template #description="{ label }">
            <span class="text-xs md:text-base">
              {{ label }}
            </span>
            <WikiTooltipComponent
              path="/platform/results/energy"
              class="top-1 ml-1 inline-block md:top-0.5"
            />
          </template>
        </StatComponent>
        <!-- Stat revenue -->
        <StatComponent
          :label="'Resultaat' + (totals.isFinal ? '' : ' (voorlopig)')"
          :value="totals.revenue"
          :precision="2"
          format="€ _"
          class="flex-1"
        >
          <template #icon>
            <BaseIcon name="money-euro-circle" filled class="fill-finance" />
          </template>
          <template #description="{ label }">
            <span class="text-xs md:text-base">
              {{ label }}
            </span>
            <WikiTooltipComponent
              path="/platform/results/result"
              class="top-1 ml-1 inline-block md:top-0.5"
            />
          </template>
        </StatComponent>
      </div>
      <!-- Chart -->
      <div class="h-[350px]">
        <TimeseriesChart
          v-if="chartData"
          :chart-type="step === 'minute' ? 'step' : 'bar'"
          :series="displaySeries"
          :axes="axes"
          :data="chartData"
          :x-limits="chartLimits"
          :time-unit="['day', 'month', 'year'].includes(step) ? step : null"
          aligned-axes
        >
          <template #left-axis-title="{ title }">
            <BaseIcon
              :name="mode !== 'finance' ? 'flashlight' : 'money-euro-circle'"
              filled
              class="text-lg"
              :class="mode !== 'finance' ? 'fill-energy' : 'fill-finance'"
            />
            {{ title }}
          </template>
          <template v-if="mode === 'both'" #right-axis-title="{ title }">
            <BaseIcon
              name="money-euro-circle"
              filled
              class="fill-finance text-lg"
            />
            {{ title }}
          </template>
          <template
            v-for="serie in Object.values(provisionalKeyMap)"
            :key="serie"
            #[serie]="{ label }"
          >
            {{ label }}
            <WikiTooltipComponent
              path="/platform/results/provisional-results"
              class="top-0.5 inline-block"
            />
          </template>
          <template v-if="meteringPoint.ean" #legend-annotation>
            <BaseBadge>
              <span class="font-bold">EAN</span>&nbsp;{{ meteringPoint.ean }}
            </BaseBadge>
          </template>
        </TimeseriesChart>
      </div>
    </LoadingWrapper>
    <div v-else class="flex h-full items-center justify-center italic md:p-6">
      <p>
        Selecteer een asset of portfolio voor een grafiek van de resultaten.
      </p>
    </div>
  </BaseCard>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  endOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns"
import { debounce } from "@/helpers/debounce.js"
import { categorizeRecords } from "@/services/repowerRecordService.js"
import { colors } from "@/services/themeService.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseCard,
  BaseIcon,
  BaseBadge,
  TextSubtitle,
  LoadingWrapper,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import StatComponent from "@/components/information/StatComponent.vue"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  /**
   * Can be either an asset or a portfolio.
   */
  meteringPoint: {
    type: Object,
    default: null,
  },
  /**
   * Array of two Date objects; the last day is included in the range.
   */
  dateRange: {
    type: Array,
    required: true,
  },
  /**
   * Determines which data series are shown/hidden.
   */
  mode: {
    required: true,
    validator: (value) => ["energy", "finance", "both"].includes(value),
  },
  cardTitle: {
    type: String,
    default: "",
  },
})

const notificationStore = useNotificationStore()
const dataset = ref({})
const loading = ref(false)
const loadingFailed = ref(false)

const provisionalKeyMap = {
  allocation: "allocationProvisional",
  revenue: "revenueProvisional",
  nomination: "nominationProvisional",
}

const axes = {
  energy: {
    title: "Energie",
    unit: "Wh",
    precision: 0,
  },
  finance: {
    title: "Financieel",
    format: "€ _",
    precision: 0,
  },
}

const series = {
  allocation: {
    label: "Energie",
    axis: "energy",
    rescale: 1000,
    stack: "allocation",
    colorScale: colors.energy,
  },
  allocationProvisional: {
    label: "Energie (voorlopig)",
    axis: "energy",
    rescale: 1000,
    stack: "allocation",
    secondary: true,
    colorScale: colors.energy,
  },
  nomination: {
    label: "Nominatie",
    axis: "energy",
    rescale: 1000,
    stack: "nomination",
    colorScale: colors.lightgray,
  },
  nominationProvisional: {
    label: "Nominatie (voorlopig)",
    axis: "energy",
    rescale: 1000,
    stack: "nomination",
    secondary: true,
    colorScale: colors.lightgray,
  },
  revenue: {
    label: "Resultaat",
    axis: "finance",
    stack: "revenue",
    colorScale: colors.finance,
  },
  revenueProvisional: {
    label: "Resultaat (voorlopig)",
    axis: "finance",
    stack: "revenue",
    secondary: true,
    colorScale: colors.finance,
  },
}

const freq = {
  minute: "15min",
  day: "1D",
  month: "MS",
  year: "YS",
}

const step = computed(() => {
  const days =
    1 + differenceInCalendarDays(props.dateRange[1], props.dateRange[0])
  const months =
    1 + differenceInCalendarMonths(props.dateRange[1], props.dateRange[0])
  if (days < 5) {
    return "minute"
  } else if (days < 32) {
    return "day"
  } else if (months < 25) {
    return "month"
  } else {
    return "year"
  }
})

const chartLimits = computed(() => {
  switch (step.value) {
    case "minute":
      return [props.dateRange[0], endOfDay(props.dateRange[1])]
    case "month":
      return [props.dateRange[0], startOfMonth(props.dateRange[1])]
    case "year":
      return [props.dateRange[0], startOfYear(props.dateRange[1])]
    default:
      return [props.dateRange[0], props.dateRange[1]]
  }
})

const displaySeries = computed(() => {
  switch (props.mode) {
    case "energy":
      return {
        allocation: series.allocation,
        allocationProvisional: series.allocationProvisional,
        nomination: series.nomination,
        nominationProvisional: series.nominationProvisional,
      }
    case "finance":
      return {
        revenue: series.revenue,
        revenueProvisional: series.revenueProvisional,
      }
    default:
      return {
        allocation: series.allocation,
        allocationProvisional: series.allocationProvisional,
        revenue: series.revenue,
        revenueProvisional: series.revenueProvisional,
      }
  }
})

/**
 * Split the series in the dataset into provisional and final results.
 */
const chartData = computed(() => {
  const { Total: _, ...data } = dataset.value
  const splitData = {}
  Object.entries(data).forEach(([datetime, { isFinal, ...values }]) => {
    if (!isFinal) {
      for (const key in provisionalKeyMap) {
        if (key in values) {
          values[provisionalKeyMap[key]] = values[key]
          delete values[key]
        }
      }
    }
    splitData[datetime] = values
  })
  return splitData
})

const totals = computed(() => {
  const { Total: data, ..._ } = dataset.value
  return data ? data : {}
})

/**
 * Categorizes the records into the desired series.
 */
function onNewCollection(collection) {
  Object.entries(collection.data).forEach(([datetime, values]) => {
    dataset.value[datetime] = categorizeRecords(values)
  })
  loading.value = false
}

function onLoadCollectionFailed(error) {
  loadingFailed.value = true
  notificationStore.pushError(
    "Fout bij het ophalen van resultaten",
    `De resultaten van een asset konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-asset-results-error",
  )
}

let debouncedloadRecordStreamCollection = null

watch(
  [() => props.meteringPoint, () => props.dateRange],
  ([meteringPoint, dateRange]) => {
    if (!meteringPoint) return

    debouncedloadRecordStreamCollection = debounce(
      meteringPoint.loadRecordStreamCollection,
      200,
    )

    loading.value = true
    loadingFailed.value = false
    dataset.value = {}
    debouncedloadRecordStreamCollection(
      freq[step.value],
      ...dateRange,
      false,
      false,
      onNewCollection,
      onLoadCollectionFailed,
    )
  },
  { immediate: true },
)
</script>
