import { defineStore } from "pinia"
import {
  fetchApiJson,
  makeDataFetchObject,
  prepareDataFetch,
  handleDataFetchPromise,
} from "@/services/repowerAPIService.js"

export default function (portfolioId) {
  const key = `singlePortfolioStore-${portfolioId}`
  return defineStore(key, {
    state: () => ({
      properties: {},
      recordStreamCollections: makeDataFetchObject(),
      tableData: makeDataFetchObject(),
      exportData: makeDataFetchObject(),
    }),
    getters: {
      id: (state) => state.properties?.id,
      name: (state) => state.properties?.name,
    },
    actions: {
      setPortfolio(portfolio) {
        this.properties = portfolio
      },
      loadRecordStreamCollection(
        freq,
        dateStart,
        dateEnd,
        getExport,
        onlyTotals,
        onSuccess,
        onFail,
        // Used to determine which cache to use
        isResultsTable = false,
      ) {
        let cache = this.returnCache(getExport, isResultsTable)
        const result = prepareDataFetch(
          cache,
          freq,
          dateStart,
          dateEnd,
          false,
          onlyTotals,
          onSuccess,
        )

        if (!result) return
        const { params, uid } = result
        const promise = fetchApiJson(
          `/v2/portfolios/${this.id}/record-stream-collections/`,
        ).then((collections) => {
          if (collections.length == 0) throw new Error("No collections present")
          return fetchApiJson(
            `/v2/record-streams/${collections[0].collection_id}/` +
              `${getExport ? "export/" : ""}?${params}`,
          )
        })
        handleDataFetchPromise(cache, uid, promise, params, onSuccess, onFail)
      },
      returnCache(getExport, isResultsTable) {
        if (getExport) {
          return this.exportData
        }
        return isResultsTable ? this.tableData : this.recordStreamCollections
      },
    },
  })() // <-- Note the () here to call the function
}
